import { Facebook, Instagram, LinkedIn, Pinterest, Twitter, WhatsApp, YouTube } from "@mui/icons-material";

const DefaultConstants = {
    baseUrl: process.env.REACT_APP_BASE_URL || '',
    systemName: 'Saga Systems',
    developer: {
        name: 'NexTech Solutions',
        url: 'https://www.linkedin.com/in/reginaldo-savian-jr-045820141/'
    },
    phones: [
        '(11) 3225-0908',
        '(11) 98694-0275 (pós vendas)',
        '(11) 94062-6974 (projetos)'
    ],
    emails: [
        'saga@sagasystems.com.br'
    ],
    address: {
        street: {
            pt_br: 'Praça da República, 386 - 5º Cj 53',
            en_us: '386, Republic square - 5º Cj 53',
            es_es: 'Plaza de la república, 386 - 5º Cj 53'
        },
        city: 'São Paulo/SP',
        zipCode: '01045-000',
        googleMaps: "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1026.4110798530396!2d-46.64206941261307!3d-23.542812493149224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjPCsDMyJzMzLjYiUyA0NsKwMzgnMjkuMiJX!5e0!3m2!1spt-BR!2sbr!4v1712603726185!5m2!1spt-BR!2sbr"
    },
    catalog: {
        url: 'https://online.fliphtml5.com/rsatm/uprn/',
        title: 'Catalog'
    },
    home: {
        slides: [
            {
                background: '/assets/images/home/1.png',
                text: {
                    title: 'homeWallpaper1Description',
                    message: undefined
                },
                align: 'center',
                button: undefined
            }, {
                background: '/assets/images/home/2.png',
                text: {
                    title: 'electronicLocks',
                    message: 'homeWallpaper2Description'
                },
                align: 'left',
                button: {
                    text: 'seeProducts',
                    link: '/products/electronicLocks',
                    internal: true
                }
            }, {
                background: '/assets/images/home/3.png',
                text: {
                    title: 'electronicSafes',
                    message: 'homeWallpaper3Description'
                },
                align: 'left',
                button: {
                    text: 'seeProducts',
                    link: '/products/electronicSafes',
                    internal: true
                }
            }, {
                background: '/assets/images/home/4.png',
                text: {
                    title: 'energySavers',
                    message: 'homeWallpaper4Description'
                },
                align: 'left',
                button: {
                    text: 'seeProducts',
                    link: '/products/energySavers',
                    internal: true
                }
            }
        ]
    },
    products: {
        title: 'products',
        subtitle: 'productsSubtitle',
        description: 'productsDescription',
        wallpaper: '/assets/images/products/wallpaper.png',
        items: [
            {
                name: 'electronicLocks',
                icon: '/assets/images/products/electronicLocks/icon.png',
                image: '/assets/images/products/electronicLocks/wallpaper.png',
                description: 'electronicLocksDescription',
                homeDescription: 'electronicLocksHomeDescription',
                products: [
                    {
                        id: '1',
                        name: 'electronicLock1',
                        wallpaper: '/assets/images/products/electronicLocks/1_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/electronicLocks/1_1.png',
                                '/assets/images/products/electronicLocks/1_2.png',
                                '/assets/images/products/electronicLocks/1_3.png',
                                '/assets/images/products/electronicLocks/1_4.png',
                            ],
                            en_us: [
                                '/assets/images/products/electronicLocks/1_1.png',
                                '/assets/images/products/electronicLocks/1_2.png',
                                '/assets/images/products/electronicLocks/1_3.png',
                                '/assets/images/products/electronicLocks/1_4.png',
                            ],
                            es_es: [
                                '/assets/images/products/electronicLocks/1_1.png',
                                '/assets/images/products/electronicLocks/1_2.png',
                                '/assets/images/products/electronicLocks/1_3.png',
                                '/assets/images/products/electronicLocks/1_4.png',
                            ]
                        },
                        description: 'electronicLock1Description',
                    }, {
                        id: '2',
                        name: 'electronicLock2',
                        wallpaper: '/assets/images/products/electronicLocks/2_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/electronicLocks/2_1.png',
                                '/assets/images/products/electronicLocks/2_2.png',
                                '/assets/images/products/electronicLocks/2_3.png',
                                '/assets/images/products/electronicLocks/2_4.png',
                                '/assets/images/products/electronicLocks/2_5.png',
                                '/assets/images/products/electronicLocks/2_6.png',
                                '/assets/images/products/electronicLocks/2_7.png'
                            ],
                            en_us: [
                                '/assets/images/products/electronicLocks/2_1.png',
                                '/assets/images/products/electronicLocks/2_2.png',
                                '/assets/images/products/electronicLocks/2_3.png',
                                '/assets/images/products/electronicLocks/2_4.png',
                                '/assets/images/products/electronicLocks/2_5.png',
                                '/assets/images/products/electronicLocks/2_6.png',
                                '/assets/images/products/electronicLocks/2_7.png'
                            ],
                            es_es: [
                                '/assets/images/products/electronicLocks/2_1.png',
                                '/assets/images/products/electronicLocks/2_2.png',
                                '/assets/images/products/electronicLocks/2_3.png',
                                '/assets/images/products/electronicLocks/2_4.png',
                                '/assets/images/products/electronicLocks/2_5.png',
                                '/assets/images/products/electronicLocks/2_6.png',
                                '/assets/images/products/electronicLocks/2_7.png'
                            ]
                        },
                        description: 'electronicLock2Description',
                    }, {
                        id: '3',
                        name: 'electronicLock3',
                        wallpaper: '/assets/images/products/electronicLocks/3_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/electronicLocks/3_1.png',
                                '/assets/images/products/electronicLocks/3_2.png',
                                '/assets/images/products/electronicLocks/3_3.png',
                                '/assets/images/products/electronicLocks/3_4.png',
                                '/assets/images/products/electronicLocks/3_5.png'
                            ],
                            en_us: [
                                '/assets/images/products/electronicLocks/3_1.png',
                                '/assets/images/products/electronicLocks/3_2.png',
                                '/assets/images/products/electronicLocks/3_3.png',
                                '/assets/images/products/electronicLocks/3_4.png',
                                '/assets/images/products/electronicLocks/3_5.png'
                            ], 
                            es_es: [
                                '/assets/images/products/electronicLocks/3_1.png',
                                '/assets/images/products/electronicLocks/3_2.png',
                                '/assets/images/products/electronicLocks/3_3.png',
                                '/assets/images/products/electronicLocks/3_4.png',
                                '/assets/images/products/electronicLocks/3_5.png'
                            ]
                        },
                        description: 'electronicLock3Description',
                    }, {
                        id: '4',
                        name: 'electronicLock4',
                        wallpaper: '/assets/images/products/electronicLocks/4_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/electronicLocks/4_1.png',
                                '/assets/images/products/electronicLocks/4_2.png'
                            ],
                            en_us: [
                                '/assets/images/products/electronicLocks/4_1.png',
                                '/assets/images/products/electronicLocks/4_2.png'
                            ],
                            es_es: [
                                '/assets/images/products/electronicLocks/4_1.png',
                                '/assets/images/products/electronicLocks/4_2.png'
                            ]
                        },
                        description: 'electronicLock4Description',
                    }, {
                        id: '5',
                        name: 'electronicLock5',
                        wallpaper: '/assets/images/products/electronicLocks/5_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/electronicLocks/5_1.png',
                                '/assets/images/products/electronicLocks/5_2.png'
                            ],
                            en_us: [
                                '/assets/images/products/electronicLocks/5_1.png',
                                '/assets/images/products/electronicLocks/5_2.png'
                            ],
                            es_es: [
                                '/assets/images/products/electronicLocks/5_1.png',
                                '/assets/images/products/electronicLocks/5_2.png'
                            ]
                        },
                        description: 'electronicLock5Description',
                    },
                ]
            }, {
                name: 'electronicSafes',
                icon: '/assets/images/products/electronicSafes/icon.png',
                image: '/assets/images/products/electronicSafes/wallpaper.png',
                description: 'electronicSafesDescription',
                homeDescription: 'electronicSafesHomeDescription',
                products: [
                    {
                        id: '1',
                        name: 'electronicSafe1',
                        wallpaper: '/assets/images/products/electronicSafes/1_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/electronicSafes/1_1.png',
                                '/assets/images/products/electronicSafes/1_2.png',
                                '/assets/images/products/electronicSafes/1_3.png',
                                '/assets/images/products/electronicSafes/1_4.png',
                                '/assets/images/products/electronicSafes/1_5.png',
                                '/assets/images/products/electronicSafes/1_6.png'
                            ],
                            en_us: [
                                '/assets/images/products/electronicSafes/1_1.png',
                                '/assets/images/products/electronicSafes/1_2.png',
                                '/assets/images/products/electronicSafes/1_3.png',
                                '/assets/images/products/electronicSafes/1_4.png',
                                '/assets/images/products/electronicSafes/1_5.png',
                                '/assets/images/products/electronicSafes/1_6.png'
                            ],
                            es_es: [
                                '/assets/images/products/electronicSafes/1_1.png',
                                '/assets/images/products/electronicSafes/1_2.png',
                                '/assets/images/products/electronicSafes/1_3.png',
                                '/assets/images/products/electronicSafes/1_4.png',
                                '/assets/images/products/electronicSafes/1_5.png',
                                '/assets/images/products/electronicSafes/1_6.png'
                            ]
                        },
                        description: 'electronicSafe1Description',
                    }, {
                        id: '2',
                        name: 'electronicSafe2',
                        wallpaper: '/assets/images/products/electronicSafes/2_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/electronicSafes/2_1.png',
                                '/assets/images/products/electronicSafes/2_2.png',
                                '/assets/images/products/electronicSafes/2_3.png',
                                '/assets/images/products/electronicSafes/2_4.png',
                                '/assets/images/products/electronicSafes/2_5.png',
                                '/assets/images/products/electronicSafes/2_6.png',
                                '/assets/images/products/electronicSafes/2_7.png',
                                '/assets/images/products/electronicSafes/2_8.png',
                                '/assets/images/products/electronicSafes/2_9.png',
                                '/assets/images/products/electronicSafes/2_10.png'
                            ],
                            en_us: [
                                '/assets/images/products/electronicSafes/2_1.png',
                                '/assets/images/products/electronicSafes/2_2.png',
                                '/assets/images/products/electronicSafes/2_3.png',
                                '/assets/images/products/electronicSafes/2_4.png',
                                '/assets/images/products/electronicSafes/2_5.png',
                                '/assets/images/products/electronicSafes/2_6.png',
                                '/assets/images/products/electronicSafes/2_7.png',
                                '/assets/images/products/electronicSafes/2_8.png',
                                '/assets/images/products/electronicSafes/2_9.png',
                                '/assets/images/products/electronicSafes/2_10.png'
                            ],
                            es_es: [
                                '/assets/images/products/electronicSafes/2_1.png',
                                '/assets/images/products/electronicSafes/2_2.png',
                                '/assets/images/products/electronicSafes/2_3.png',
                                '/assets/images/products/electronicSafes/2_4.png',
                                '/assets/images/products/electronicSafes/2_5.png',
                                '/assets/images/products/electronicSafes/2_6.png',
                                '/assets/images/products/electronicSafes/2_7.png',
                                '/assets/images/products/electronicSafes/2_8.png',
                                '/assets/images/products/electronicSafes/2_9.png',
                                '/assets/images/products/electronicSafes/2_10.png'
                            ]
                        },
                        description: 'electronicSafe2Description',
                    }, {
                        id: '3',
                        name: 'electronicSafe3',
                        wallpaper: '/assets/images/products/electronicSafes/3_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/electronicSafes/3_1.png',
                                '/assets/images/products/electronicSafes/3_2.png',
                                '/assets/images/products/electronicSafes/3_3.png',
                                '/assets/images/products/electronicSafes/3_4.png',
                                '/assets/images/products/electronicSafes/3_5.png',
                                '/assets/images/products/electronicSafes/3_6.png',
                                '/assets/images/products/electronicSafes/3_7.png',
                                '/assets/images/products/electronicSafes/3_8.png'
                            ],
                            en_us: [
                                '/assets/images/products/electronicSafes/3_1.png',
                                '/assets/images/products/electronicSafes/3_2.png',
                                '/assets/images/products/electronicSafes/3_3.png',
                                '/assets/images/products/electronicSafes/3_4.png',
                                '/assets/images/products/electronicSafes/3_5.png',
                                '/assets/images/products/electronicSafes/3_6.png',
                                '/assets/images/products/electronicSafes/3_7.png',
                                '/assets/images/products/electronicSafes/3_8.png'
                            ],
                            es_es: [
                                '/assets/images/products/electronicSafes/3_1.png',
                                '/assets/images/products/electronicSafes/3_2.png',
                                '/assets/images/products/electronicSafes/3_3.png',
                                '/assets/images/products/electronicSafes/3_4.png',
                                '/assets/images/products/electronicSafes/3_5.png',
                                '/assets/images/products/electronicSafes/3_6.png',
                                '/assets/images/products/electronicSafes/3_7.png',
                                '/assets/images/products/electronicSafes/3_8.png'
                            ]
                        },
                        description: 'electronicSafe3Description',
                    }, {
                        id: '4',
                        name: 'electronicSafe4',
                        wallpaper: '/assets/images/products/electronicSafes/4_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/electronicSafes/4_1.png'
                            ],
                            en_us: [
                                '/assets/images/products/electronicSafes/4_1.png'
                            ],
                            es_es: [
                                '/assets/images/products/electronicSafes/4_1.png'
                            ]
                        },
                        description: 'electronicSafe4Description',
                    }
                ]
            }, {
                name: 'energySavers',
                icon: '/assets/images/products/energySavers/icon.png',
                image: '/assets/images/products/energySavers/wallpaper.png',
                description: 'energySaversDescription',
                homeDescription: 'energySaversHomeDescription',
                products: [
                    {
                        id: '1',
                        name: 'energySaver1',
                        wallpaper: '/assets/images/products/energySavers/1_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/energySavers/1_1.png',
                                '/assets/images/products/energySavers/1_2.png'
                            ],
                            en_us: [
                                '/assets/images/products/energySavers/1_1.png',
                                '/assets/images/products/energySavers/1_2.png'
                            ],
                            es_es: [
                                '/assets/images/products/energySavers/1_1.png',
                                '/assets/images/products/energySavers/1_2.png'
                            ]
                        },
                        description: 'energySaver1Description',
                    }, {
                        id: '2',
                        name: 'energySaver2',
                        wallpaper: '/assets/images/products/energySavers/2_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/energySavers/2_1.png',
                                '/assets/images/products/energySavers/2_2.png',
                            ],
                            en_us: [
                                '/assets/images/products/energySavers/2_1.png',
                                '/assets/images/products/energySavers/2_2.png',
                            ],
                            es_es: [
                                '/assets/images/products/energySavers/2_1.png',
                                '/assets/images/products/energySavers/2_2.png',
                            ]
                        },
                        description: 'energySaver2Description',
                    }, {
                        id: '3',
                        name: 'energySaver3',
                        wallpaper: '/assets/images/products/energySavers/3_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/energySavers/3_1.png',
                                '/assets/images/products/energySavers/3_2.png',
                            ],
                            en_us: [
                                '/assets/images/products/energySavers/3_1.png',
                                '/assets/images/products/energySavers/3_2.png',
                            ],
                            es_es: [
                                '/assets/images/products/energySavers/3_1.png',
                                '/assets/images/products/energySavers/3_2.png',
                            ]
                        },
                        description: 'energySaver3Description',
                    }, {
                        id: '4',
                        name: 'energySaver4',
                        wallpaper: '/assets/images/products/energySavers/4_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/energySavers/4_1.png',
                                '/assets/images/products/energySavers/4_2.png',
                                '/assets/images/products/energySavers/4_3.png',
                            ],
                            en_us: [
                                '/assets/images/products/energySavers/4_1.png',
                                '/assets/images/products/energySavers/4_2.png',
                                '/assets/images/products/energySavers/4_3.png',
                            ],
                            es_es: [
                                '/assets/images/products/energySavers/4_1.png',
                                '/assets/images/products/energySavers/4_2.png',
                                '/assets/images/products/energySavers/4_3.png',
                            ]
                        },
                        description: 'energySaver4Description',
                    }, {
                        id: '5',
                        name: 'energySaver5',
                        wallpaper: '/assets/images/products/energySavers/5_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/energySavers/5_1.png',
                            ],
                            en_us: [
                                '/assets/images/products/energySavers/5_1.png',
                            ],
                            es_es: [
                                '/assets/images/products/energySavers/5_1.png',
                            ]
                        },
                        description: 'energySaver5Description',
                    }, {
                        id: '6',
                        name: 'energySaver6',
                        wallpaper: '/assets/images/products/energySavers/6_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/energySavers/6_1.png',
                            ],
                            en_us: [
                                '/assets/images/products/energySavers/6_1.png',
                            ],
                            es_es: [
                                '/assets/images/products/energySavers/6_1.png',
                            ]
                        },
                        description: 'energySaver6Description',
                    }, {
                        id: '7',
                        name: 'energySaver7',
                        wallpaper: '/assets/images/products/energySavers/7_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/energySavers/7_1.png',
                            ],
                            en_us: [
                                '/assets/images/products/energySavers/7_1.png',
                            ],
                            es_es: [
                                '/assets/images/products/energySavers/7_1.png',
                            ]
                        },
                        description: 'energySaver7Description',
                    }, {
                        id: '8',
                        name: 'energySaver8',
                        wallpaper: '/assets/images/products/energySavers/8_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/energySavers/8_1.png',
                            ],
                            en_us: [
                                '/assets/images/products/energySavers/8_1.png',
                            ],
                            es_es: [
                                '/assets/images/products/energySavers/8_1.png',
                            ]
                        },
                        description: 'energySaver8Description',
                    }, {
                        id: '9',
                        name: 'energySaver9',
                        wallpaper: '/assets/images/products/energySavers/9_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/energySavers/9_1.png',
                                '/assets/images/products/energySavers/9_2.png',
                                '/assets/images/products/energySavers/9_3.png',
                            ],
                            en_us: [
                                '/assets/images/products/energySavers/9_1.png',
                                '/assets/images/products/energySavers/9_2.png',
                                '/assets/images/products/energySavers/9_3.png',
                            ],
                            es_es: [
                                '/assets/images/products/energySavers/9_1.png',
                                '/assets/images/products/energySavers/9_2.png',
                                '/assets/images/products/energySavers/9_3.png',
                            ]
                        },
                        description: 'energySaver9Description',
                    }, {
                        id: '10',
                        name: 'energySaver10',
                        wallpaper: '/assets/images/products/energySavers/10_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/energySavers/10_1.png',
                            ],
                            en_us: [
                                '/assets/images/products/energySavers/10_1.png',
                            ],
                            es_es: [
                                '/assets/images/products/energySavers/10_1.png',
                            ]
                        },
                        description: 'energySaver10Description',
                    }, {
                        id: '11',
                        name: 'energySaver11',
                        wallpaper: '/assets/images/products/energySavers/11_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/energySavers/11_1.png',
                                '/assets/images/products/energySavers/11_2.png',
                                '/assets/images/products/energySavers/11_3.png',
                            ],
                            en_us: [
                                '/assets/images/products/energySavers/11_1.png',
                                '/assets/images/products/energySavers/11_2.png',
                                '/assets/images/products/energySavers/11_3.png',
                            ],
                            es_es: [
                                '/assets/images/products/energySavers/11_1.png',
                                '/assets/images/products/energySavers/11_2.png',
                                '/assets/images/products/energySavers/11_3.png',
                            ]
                        },
                        description: 'energySaver11Description',
                    }, {
                        id: '12',
                        name: 'energySaver12',
                        wallpaper: '/assets/images/products/energySavers/12_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/energySavers/12_1.png',
                                '/assets/images/products/energySavers/12_2.png',
                                '/assets/images/products/energySavers/12_3.png',
                            ],
                            en_us: [
                                '/assets/images/products/energySavers/12_1.png',
                                '/assets/images/products/energySavers/12_2.png',
                                '/assets/images/products/energySavers/12_3.png',
                            ],
                            es_es: [
                                '/assets/images/products/energySavers/12_1.png',
                                '/assets/images/products/energySavers/12_2.png',
                                '/assets/images/products/energySavers/12_3.png',
                            ]
                        },
                        description: 'energySaver12Description',
                    }
                ]
            }, {
                name: 'accessories',
                icon: '/assets/images/products/accessories/icon.png',
                image: '/assets/images/products/accessories/wallpaper.png',
                description: 'accessoriesDescription',
                homeDescription: 'accessoriesHomeDescription',
                products: [
                    {
                        id: '1',
                        name: 'accessory1',
                        wallpaper: '/assets/images/products/accessories/1_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/accessories/1_1.png',
                                '/assets/images/products/accessories/1_2.png',
                                '/assets/images/products/accessories/1_3.png'
                            ],
                            en_us: [
                                '/assets/images/products/accessories/1_1.png',
                                '/assets/images/products/accessories/1_2.png',
                                '/assets/images/products/accessories/1_3.png'
                            ],
                            es_es: [
                                '/assets/images/products/accessories/1_1.png',
                                '/assets/images/products/accessories/1_2.png',
                                '/assets/images/products/accessories/1_3.png'
                            ]
                        },
                        description: 'accessory1Description',
                    }, {
                        id: '2',
                        name: 'accessory2',
                        wallpaper: '/assets/images/products/accessories/2_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/accessories/2_1.png',
                                '/assets/images/products/accessories/2_2.png',
                                '/assets/images/products/accessories/2_3.png',
                                '/assets/images/products/accessories/2_4.png',
                            ],
                            en_us: [
                                '/assets/images/products/accessories/2_1.png',
                                '/assets/images/products/accessories/2_2.png',
                                '/assets/images/products/accessories/2_3.png',
                                '/assets/images/products/accessories/2_4.png',
                            ],
                            es_es: [
                                '/assets/images/products/accessories/2_1.png',
                                '/assets/images/products/accessories/2_2.png',
                                '/assets/images/products/accessories/2_3.png',
                                '/assets/images/products/accessories/2_4.png',
                            ]
                        },
                        description: 'accessory2Description',
                    }, {
                        id: '3',
                        name: 'accessory3',
                        wallpaper: '/assets/images/products/accessories/3_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/accessories/3_1.png',
                                '/assets/images/products/accessories/3_2.png',
                            ],
                            en_us: [
                                '/assets/images/products/accessories/3_1.png',
                                '/assets/images/products/accessories/3_2.png',
                            ],
                            es_es: [
                                '/assets/images/products/accessories/3_1.png',
                                '/assets/images/products/accessories/3_2.png',
                            ]
                        },
                        description: 'accessory3Description',
                    }, {
                        id: '4',
                        name: 'accessory4',
                        wallpaper: '/assets/images/products/accessories/4_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/accessories/4_1.png',
                                '/assets/images/products/accessories/4_2.png',
                            ],
                            en_us: [
                                '/assets/images/products/accessories/4_1.png',
                                '/assets/images/products/accessories/4_2.png',
                            ],
                            es_es: [
                                '/assets/images/products/accessories/4_1.png',
                                '/assets/images/products/accessories/4_2.png',
                            ]
                        },
                        description: 'accessory4Description',
                    }, {
                        id: '5',
                        name: 'accessory5',
                        wallpaper: '/assets/images/products/accessories/5_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/accessories/5_1.png',
                                '/assets/images/products/accessories/5_2.png',
                                '/assets/images/products/accessories/5_3.png',
                                '/assets/images/products/accessories/5_4.png',
                                '/assets/images/products/accessories/5_5.png',
                                '/assets/images/products/accessories/5_6.png',
                                '/assets/images/products/accessories/5_7.png',
                                '/assets/images/products/accessories/5_8.png',
                                '/assets/images/products/accessories/5_9.png',
                                '/assets/images/products/accessories/5_10.png',
                                '/assets/images/products/accessories/5_11.png',
                            ],
                            en_us: [
                                '/assets/images/products/accessories/5_1.png',
                                '/assets/images/products/accessories/5_2.png',
                                '/assets/images/products/accessories/5_3.png',
                                '/assets/images/products/accessories/5_4.png',
                                '/assets/images/products/accessories/5_5.png',
                                '/assets/images/products/accessories/5_6.png',
                                '/assets/images/products/accessories/5_7.png',
                                '/assets/images/products/accessories/5_8.png',
                                '/assets/images/products/accessories/5_9.png',
                                '/assets/images/products/accessories/5_10.png',
                                '/assets/images/products/accessories/5_11.png',
                            ],
                            es_es: [
                                '/assets/images/products/accessories/5_1.png',
                                '/assets/images/products/accessories/5_2.png',
                                '/assets/images/products/accessories/5_3.png',
                                '/assets/images/products/accessories/5_4.png',
                                '/assets/images/products/accessories/5_5.png',
                                '/assets/images/products/accessories/5_6.png',
                                '/assets/images/products/accessories/5_7.png',
                                '/assets/images/products/accessories/5_8.png',
                                '/assets/images/products/accessories/5_9.png',
                                '/assets/images/products/accessories/5_10.png',
                                '/assets/images/products/accessories/5_11.png',
                            ]
                        },
                        description: 'accessory5Description',
                    }, {
                        id: '6',
                        name: 'accessory6',
                        wallpaper: '/assets/images/products/accessories/6_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/accessories/6_1.png',
                            ],
                            en_us: [
                                '/assets/images/products/accessories/6_1.png',
                            ],
                            es_es: [
                                '/assets/images/products/accessories/6_1.png',
                            ]
                        },
                        description: 'accessory6Description',
                    }, {
                        id: '7',
                        name: 'accessory7',
                        wallpaper: '/assets/images/products/accessories/7_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/accessories/7_1.png',
                                '/assets/images/products/accessories/7_2.png',
                            ],
                            en_us: [
                                '/assets/images/products/accessories/7_1.png',
                                '/assets/images/products/accessories/7_2.png',
                            ],
                            es_es: [
                                '/assets/images/products/accessories/7_1.png',
                                '/assets/images/products/accessories/7_2.png',
                            ]
                        },
                        description: 'accessory7Description',
                    }, {
                        id: '8',
                        name: 'accessory8',
                        wallpaper: '/assets/images/products/accessories/8_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/accessories/8_1.png',
                                '/assets/images/products/accessories/8_2.png',
                            ],
                            en_us: [
                                '/assets/images/products/accessories/8_1.png',
                                '/assets/images/products/accessories/8_2.png',
                            ],
                            es_es: [
                                '/assets/images/products/accessories/8_1.png',
                                '/assets/images/products/accessories/8_2.png',
                            ]
                        },
                        description: 'accessory8Description',
                    }, {
                        id: '9',
                        name: 'accessory9',
                        wallpaper: '/assets/images/products/accessories/9_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/accessories/9_1.png',
                                '/assets/images/products/accessories/9_2.png',
                            ],
                            en_us: [
                                '/assets/images/products/accessories/9_1.png',
                                '/assets/images/products/accessories/9_2.png',
                            ],
                            es_es: [
                                '/assets/images/products/accessories/9_1.png',
                                '/assets/images/products/accessories/9_2.png',
                            ]
                        },
                        description: 'accessory9Description',
                    }, {
                        id: '10',
                        name: 'accessory10',
                        wallpaper: '/assets/images/products/accessories/10_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/accessories/10_1.png',
                                '/assets/images/products/accessories/10_2.png',
                                '/assets/images/products/accessories/10_3.png',
                            ],
                            en_us: [
                                '/assets/images/products/accessories/10_1.png',
                                '/assets/images/products/accessories/10_2.png',
                                '/assets/images/products/accessories/10_3.png',
                            ],
                            es_es: [
                                '/assets/images/products/accessories/10_1.png',
                                '/assets/images/products/accessories/10_2.png',
                                '/assets/images/products/accessories/10_3.png',
                            ]
                        },
                        description: 'accessory10Description',
                    }, {
                        id: '11',
                        name: 'accessory11',
                        wallpaper: '/assets/images/products/accessories/11_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/accessories/11_1.png',
                                '/assets/images/products/accessories/11_2.png',
                                '/assets/images/products/accessories/11_3.png',
                            ],
                            en_us: [
                                '/assets/images/products/accessories/11_1.png',
                                '/assets/images/products/accessories/11_2.png',
                                '/assets/images/products/accessories/11_3.png',
                            ],
                            es_es: [
                                '/assets/images/products/accessories/11_1.png',
                                '/assets/images/products/accessories/11_2.png',
                                '/assets/images/products/accessories/11_3.png',
                            ]
                        },
                        description: 'accessory11Description',
                    }, {
                        id: '12',
                        name: 'accessory12',
                        wallpaper: '/assets/images/products/accessories/12_1.png',
                        images: {
                            pt_br: [
                                '/assets/images/products/accessories/12_1.png',
                                '/assets/images/products/accessories/12_2.png',
                            ],
                            en_us: [
                                '/assets/images/products/accessories/12_1.png',
                                '/assets/images/products/accessories/12_2.png',
                            ],
                            es_es: [
                                '/assets/images/products/accessories/12_1.png',
                                '/assets/images/products/accessories/12_2.png',
                            ]
                        },
                        description: 'accessory12Description',
                    }
                ]
            }
        ]
    },
    services: {
        title: 'services',
        subtitle: 'servicesSubtitle',
        description: 'servicesDescription',
        wallpaper: '/assets/images/services/wallpaper.png',
        items: [
            {
                id: '1',
                name: 'systemsRestructuring',
                wallpaper: '/assets/images/services/systemsRestructuring/wallpaper.png',
                homeDescription: 'systemsRestructuringHomeDescription',
                description: 'systemsRestructuringDescription',
                images: {
                    pt_br: [
                        '/assets/images/services/systemsRestructuring/1.png'
                    ],
                    en_us: [
                        '/assets/images/services/systemsRestructuring/1.png'
                    ], 
                    es_es: [
                        '/assets/images/services/systemsRestructuring/1.png'
                    ]
                }
            }, {
                id: '2',
                name: 'recyclingTrainings',
                wallpaper: '/assets/images/services/recyclingTrainings/wallpaper.png',
                homeDescription: 'recyclingTrainingsHomeDescription',
                description: 'recyclingTrainingsDescription',
                images: {
                    pt_br: [
                        '/assets/images/services/recyclingTrainings/1.png'
                    ],
                    en_us: [
                        '/assets/images/services/recyclingTrainings/1.png'
                    ], 
                    es_es: [
                        '/assets/images/services/recyclingTrainings/1.png'
                    ]
                }
            }, {
                id: '3',
                name: 'preventiveCorrectiveMaintenance',
                wallpaper: '/assets/images/services/preventiveCorrectiveMaintenance/wallpaper.png',
                homeDescription: 'preventiveCorrectiveMaintenanceHomeDescription',
                description: 'preventiveCorrectiveMaintenanceDescription',
                images: {
                    pt_br: [
                        '/assets/images/services/preventiveCorrectiveMaintenance/1.png'
                    ],
                    en_us: [
                        '/assets/images/services/preventiveCorrectiveMaintenance/1.png'
                    ], 
                    es_es: [
                        '/assets/images/services/preventiveCorrectiveMaintenance/1.png'
                    ]
                }
            }, {
                id: '4',
                name: 'installationPeripheralsLocks',
                wallpaper: '/assets/images/services/installationPeripheralsLocks/wallpaper.png',
                homeDescription: 'installationPeripheralsLocksHomeDescription',
                description: 'installationPeripheralsLocksDescription',
                images: {
                    pt_br: [
                        '/assets/images/services/installationPeripheralsLocks/1.png'
                    ],
                    en_us: [
                        '/assets/images/services/installationPeripheralsLocks/1.png'
                    ], 
                    es_es: [
                        '/assets/images/services/installationPeripheralsLocks/1.png'
                    ]
                }
            }, {
                id: '5',
                name: 'specializedTechnicalTeam',
                wallpaper: '/assets/images/services/specializedTechnicalTeam/wallpaper.png',
                homeDescription: 'specializedTechnicalTeamHomeDescription',
                description: 'specializedTechnicalTeamDescription',
                images: {
                    pt_br: [
                        '/assets/images/services/specializedTechnicalTeam/1.png'
                    ],
                    en_us: [
                        '/assets/images/services/specializedTechnicalTeam/1.png'
                    ], 
                    es_es: [
                        '/assets/images/services/specializedTechnicalTeam/1.png'
                    ]
                }
            }, {
                id: '6',
                name: '_24HourTechnicalAssistance',
                wallpaper: '/assets/images/services/24HourTechnicalAssistance/wallpaper.png',
                homeDescription: '_24HourTechnicalAssistanceHomeDescription',
                description: '_24HourTechnicalAssistanceDescription',
                images: {
                    pt_br: [
                        '/assets/images/services/24HourTechnicalAssistance/1.png'
                    ],
                    en_us: [
                        '/assets/images/services/24HourTechnicalAssistance/1.png'
                    ], 
                    es_es: [
                        '/assets/images/services/24HourTechnicalAssistance/1.png'
                    ]
                }
            }
        ]
    },
    about: {
        title: 'aboutUs',
        subtitle: 'aboutUsDescription',
        wallpaper: '/assets/images/about/wallpaper.png',
        homeWallpaper: '/assets/images/about/homeWallpaper.png',
        shortDescription: 'aboutShortDescription',
        video: 'assets/videos/about/video.mp4',
        images: [
            '/assets/images/about/1.png'
        ]
    },
    contact: {
        title: 'contact',
        subtitle: 'contactDescription',
        wallpaper: '/assets/images/contact/wallpaper.png',
        subjects: ['informations', 'sales', 'technicalAssistance']
    },
    footer: {
        wallpaper: '/assets/images/footer/wallpaper.png',
    },
    socialMedias: [
        {
            url: undefined,
            Icon: WhatsApp
        }, {
            url: 'https://www.facebook.com/sagasystems/?locale=pt_BR',
            Icon: Facebook
        }, {
            url: 'https://www.youtube.com/@SagaSystemsOficial',
            Icon: YouTube
        }, {
            url: 'https://www.instagram.com/sagasystems/',
            Icon: Instagram
        }, {
            url: 'https://www.linkedin.com/company/saga-systems-brasil/?originalSubdomain=br',
            Icon: LinkedIn
        }, {
            url: undefined,
            Icon: Pinterest
        }, {
            url: undefined,
            Icon: Twitter
        }
    ],
    clients: [
        {
            name: 'Hospital Dom Antonio de Alvarenga',
            image: '/assets/images/clients/1.png'
        }, {
            name: 'Hospital Albert Einstein',
            image: '/assets/images/clients/2.png'
        }, {
            name: 'Hospital A. C. Camargo',
            image: '/assets/images/clients/3.png'
        }, {
            name: 'Vogal Luxury Beach Hotel & Spa',
            image: '/assets/images/clients/4.png'
        }, {
            name: 'Vert Hotéis',
            image: '/assets/images/clients/5.png'
        }, {
            name: 'Hospital Vera Cruz Campinas',
            image: '/assets/images/clients/6.png'
        }, {
            name: 'Trip Hotels',
            image: '/assets/images/clients/7.png'
        }, {
            name: 'Transamérica Hospitality Group',
            image: '/assets/images/clients/8.png'
        }, {
            name: 'Sesc',
            image: '/assets/images/clients/9.png'
        }, {
            name: 'Rede D`Or São Luiz',
            image: '/assets/images/clients/10.png'
        }, {
            name: 'São Joaquim Hospital e Maternidade',
            image: '/assets/images/clients/11.png'
        }, {
            name: 'São Cristóvão Saúde',
            image: '/assets/images/clients/12.png'
        }, {
            name: 'San Raphael Hotel',
            image: '/assets/images/clients/13.png'
        }, {
            name: 'Radisson Aracaju',
            image: '/assets/images/clients/14.png'
        }, {
            name: 'Pratagy Beach All-Inclusive Resort',
            image: '/assets/images/clients/15.png'
        }, {
            name: 'Vale Encantado Hotel Fazenda e Pousada',
            image: '/assets/images/clients/16.png'
        }, {
            name: 'Paulista Wall Street Suites',
            image: '/assets/images/clients/17.png'
        }, {
            name: 'Parador da Figueira Pousada',
            image: '/assets/images/clients/18.png'
        }, {
            name: 'Oceani Beach Park Hotel',
            image: '/assets/images/clients/19.png'
        }, {
            name: 'Hospital Nove de Julho',
            image: '/assets/images/clients/20.png'
        }, {
            name: 'Meliã Hotels International',
            image: '/assets/images/clients/21.png'
        }, {
            name: 'Maceió Mar Hotel',
            image: '/assets/images/clients/22.png'
        }, {
            name: 'Hospital Leforte',
            image: '/assets/images/clients/23.png'
        }, {
            name: 'Kuara Hotel',
            image: '/assets/images/clients/24.png'
        }, {
            name: 'Kembali Hotel',
            image: '/assets/images/clients/25.png'
        }, {
            name: 'InterCity Hotéis Inteligentes',
            image: '/assets/images/clients/26.png'
        }, {
            name: 'Iloa Resort',
            image: '/assets/images/clients/27.png'
        }, {
            name: 'Hospital IFOR',
            image: '/assets/images/clients/28.png'
        }, {
            name: 'Hotel Manaíra',
            image: '/assets/images/clients/29.png'
        }, {
            name: 'EZ Hotéis',
            image: '/assets/images/clients/30.png'
        }, {
            name: 'Hotel Coquille',
            image: '/assets/images/clients/31.png'
        }, {
            name: 'Grupo Nobile',
            image: '/assets/images/clients/32.png'
        }, {
            name: 'Estanplaza Hotels',
            image: '/assets/images/clients/33.png'
        }, {
            name: 'Enotel Hotels & Resorts',
            image: '/assets/images/clients/34.png'
        }, {
            name: 'DPNY Beach Hotel',
            image: '/assets/images/clients/35.png'
        }, {
            name: 'Casa Grande Hotel, Resort & Spa',
            image: '/assets/images/clients/36.png'
        }, {
            name: 'Paraíso dos Colibris',
            image: '/assets/images/clients/37.png'
        }, {
            name: 'Bourbon Hotéis & Resorts',
            image: '/assets/images/clients/38.png'
        }, {
            name: 'Bonito Ecotel',
            image: '/assets/images/clients/39.png'
        }, {
            name: 'Brazil Hospitality Group',
            image: '/assets/images/clients/40.png'
        }, {
            name: 'Beach Park',
            image: '/assets/images/clients/41.png'
        }, {
            name: 'Atlantica Hotels',
            image: '/assets/images/clients/42.png'
        }, {
            name: 'Aram Hotéis',
            image: '/assets/images/clients/43.png'
        }, {
            name: 'Araçatuba Plaza Hotel',
            image: '/assets/images/clients/44.png'
        }, {
            name: 'Hospital Ana Costa',
            image: '/assets/images/clients/45.png'
        },
    ],
    testimonials: [
        {
            client: 'Hilton Garden Inn Santo André',
            person: 'Sra. Isabela Vieira',
            avatar: '/assets/images/testimonials/1.png',
            message: 'testimonial1Message'
        }, {
            client: 'Palace Hotel Pradópolis',
            avatar: '/assets/images/testimonials/2.png',
            message: 'testimonial2Message'
        }, {
            client: 'Hotel Coquille',
            avatar: '/assets/images/testimonials/3.png',
            message: 'testimonial3Message'
        }, {
            client: 'Hotel Intercity Ribeirão Preto',
            person: 'Sr. Ignacio Sasias - Gerente Geral',
            avatar: '/assets/images/testimonials/4.png',
            message: 'testimonial4Message'
        }, {
            client: 'Hotel Tryp Nações Unidas',
            avatar: '/assets/images/testimonials/5.png',
            message: 'testimonial5Message'
        }
    ],
    employees: [
        {
            name: 'Angelita Martimiano',
            phone: '(47) 99235-3580',
            email: 'cazzabless@gmail.com',
            position: 'salesRepresentative',
            location: 'Santa Catarina',
            avatar: '/assets/images/employees/15.png'
        }, {
            name: 'Antônio Rossi',
            phone: '(11) 99125-9759',
            email: 'rrx.representacoes@gmail.com',
            position: 'salesRepresentative',
            location: 'São Paulo',
            avatar: '/assets/images/employees/5.png'
        }, {
            name: 'Claúdia Moreira',
            phone: '(81) 99707-2424',
            email: 'claudia.pinto@sagasystems.com.br',
            position: 'salesRepresentative',
            location: 'Alagoas e Pernambuco',
            avatar: '/assets/images/employees/6.png'
        }, {
            name: 'Cleide Rocha',
            phone: '(11) 94705-7487',
            email: 'cleiderocharepresentações@gmail.com',
            position: 'salesRepresentative',
            location: 'São Paulo',
            avatar: '/assets/images/employees/7.png'
        }, {
            name: 'Domingos Soriano',
            phone: '(67) 99272-6625',
            email: 'vendas@deltab2b.com.br',
            position: 'salesRepresentative',
            location: 'Mato Grosso e Mato Grosso do Sul',
            avatar: '/assets/images/employees/14.png'
        }, {
            name: 'Herlley Guimarães Jorge',
            phone: '(64) 98457-7515',
            email: 'helley@guimaraesrepresentacoes.com',
            position: 'salesRepresentative',
            location: 'Goiás e Distrito Federal',
            avatar: '/assets/images/employees/13.png'
        }, {
            name: 'Ione Neves',
            phone: '(67) 99693-7779',
            email: 'inb.representacoes@gmail.com',
            position: 'salesRepresentative',
            location: 'Mato Grosso e Mato Grosso do Sul',
            avatar: '/assets/images/employees/8.png'
        }, {
            name: 'Luiz Augusto',
            phone: '(27) 99816-5119',
            email: 'solin@solin.rep.br',
            position: 'salesRepresentative',
            location: 'Espírito Santo',
            avatar: '/assets/images/employees/9.png'
        }, {
            name: 'Luiz Navarro',
            phone: '(11) 98929-0586',
            email: 'luizcnavarro@hotmail.com',
            position: 'salesRepresentative',
            location: 'São Paulo',
            avatar: '/assets/images/employees/10.png'
        }, {
            name: 'Marcos Bomfim',
            phone: '(71) 99363-4373',
            email: 'marcos.bomfim@sagasystems.com.br',
            position: 'salesRepresentative',
            location: 'Bahia e Sergipe',
            avatar: '/assets/images/employees/11.png'
        }, {
            name: 'Marcos Garcia',
            phone: '(47) 99973-9560',
            email: 'marcos@gabrimargr.com.br',
            position: 'salesRepresentative',
            location: 'Santa Catarina',
            avatar: '/assets/images/employees/16.png'
        }, {
            name: 'Ricardo Gabriel',
            phone: '(92) 98112-8833',
            email: 'ricardogabriel83@gmail.com',
            position: 'salesRepresentative',
            location: 'Amazonas',
            avatar: '/assets/images/employees/12.png'
        }, {
            name: 'Ricardo Lopes',
            phone: '(31) 99778-5819',
            email: 'ricardo@efetuar.com.br',
            position: 'salesRepresentative',
            location: 'Minas Gerais',
            avatar: '/assets/images/employees/1.png'
        }, {
            name: 'Romário Linhares',
            phone: '(86) 98841-2190',
            email: 'romariolinharesla@gmail.com',
            position: 'salesRepresentative',
            location: 'Piauí',
            avatar: '/assets/images/employees/2.png'
        }, {
            name: 'Tarcísio Paiva',
            phone: '(83) 98829-9403',
            email: 'tarcisiorep@hotmail.com',
            position: 'salesRepresentative',
            location: 'Paraíba',
            avatar: '/assets/images/employees/3.png'
        }, {
            name: 'Valmir Dantas',
            phone: '(84) 99422-2524',
            email: 'lima-valmir@uol.com.br',
            position: 'salesRepresentative',
            location: 'Rio Grande do Norte',
            avatar: '/assets/images/employees/4.png'
        }
    ]
};

const systemNameFormatted = DefaultConstants.systemName.toLowerCase().replace(/ /g, '');


const StorageConstants = {
    languageStorageName: `${systemNameFormatted}_currentlanguage`,
}

const KeyConstants = {
    privateKey: process.env.REACT_APP_PRIVATE_KEY || `${systemNameFormatted}_privatekey`
}


export {
    StorageConstants,
    KeyConstants
}

export default DefaultConstants;

