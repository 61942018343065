
const ProductsTranslation = {
    accessories: {
        pt_br: `Acessórios`,
        en_us: `Accessories`,
        es_es: `Accesorios`
    },

    accessoriesDescription: {
        pt_br: `Os acessórios Saga Systems são essenciais para otimizar o desempenho dos equipamentos, melhorar a eficiência operacional e garantir a durabilidade dos sistemas`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    accessoriesHomeDescription: {
        pt_br: `Acessórios indispensáveis e inteligentes, melhoram a segurança`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    accessory1: {
        pt_br: `Cartões RFID`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    accessory1Description: {
        pt_br: <>
            <span>Cartões de rádio frequência MIFARE, padrão SAGA ou personalizado com a arte de seu hotel, disponíveis em PVC ou em Madeira Reciclável (cartão ecológico)</span>
            <br/> <br/>

            <ul>
                <li>Faixa de operação: 13.56 MHz, ISO 14443A</li>
                <li>Retenção de dados por mais de 10 anos</li>
                <li>Sem problemas de "desmagnetização"</li>
                <li>Dimensões: 5.4 x 8.6 cm</li>
                <li>Todos os modelos são Impermeáveis</li>
            </ul>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    accessory2: {
        pt_br: `Fecho de segurança`,
        en_us: `Security clasp`,
        es_es: `Cierre de seguridad`
    },

    accessory2Description: {
        pt_br: <>
            <span>O Fecho de Segurança, popularmente conhecido como "Pega-Ladrão", é um item de segurança que limita a abertura da porta.</span>
            <br/> <br/>

            <span>Além de permitir que o hóspede abra parcialmente a porta para coletar alguma encomenda ou documento, o fecho de segurança também atua impedindo que, por exemplo, crianças pequenas deixem a acomodação.</span>
            <br/> <br/>

            <span>Disponível nas versões:</span>
            <br/> <br/>

            <span>
                <b>Luxo:</b> Fecho de Segurança Corrente Luxo em Aço Forjado e Liga de Zinco.
            </span>
            <br/> <br/>

            <span>
                <b>Básico:</b> Fecho Trifor em Zamak Cromado com calço em ABS.
            </span>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    accessory3: {
        pt_br: `Prendedor de porta`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    accessory3Description: {
        pt_br: <>
            <span>O prendedor magnético de porta, tem função de manter a porta aberta e evitar que correntes de ar fechem a porta de repente ou fique batendo.</span>
            <br/> <br/>

            <span>
                <b>Dimensões:</b> 8,5 cm (altura) x 4,8 cm (diâmetro)
            </span>
            <br/>

            <span>
                <b>Material:</b> Aço Inox escovado
            </span>
            <br/>

            <span>
                <b>Peso (kg):</b> 0,132kg
            </span>
            <br/>

            <span>
                <b>Instalação:</b> parafusado no piso ou na parede
            </span>
            <br/>

            <span>
                <b>Indicação:</b> portas comerciais e residenciais
            </span>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    accessory4: {
        pt_br: `Olho mágico`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    accessory4Description: {
        pt_br: <>
            <span>O Olho Mágico é um dispositivo de segurança que permite que o hóspede verifique quem se encontra do lado externo de sua acomodação, antes de abrir a porta.</span>
            <br/> <br/>
            
            <span>Sua instalação é simples, e requer apenas uma furadeira e uma broca adequadas para a porta.</span>
            <br/> <br/>

            <span>Feito em ZAMAK Cromado, e disponível nas versões simples ou com tampa na parte interna.</span>
            <br/> <br/>

            <span>
                <b>Dimensões:</b> Ø 30 mm; 26-36 mm (versão simples)
            </span>
            <br/>

            <span>
                <b>Altura:</b> 3 cm
            </span>
            <br/>

            <span>
                <b>Profundidade:</b> 4 cm
            </span>
            <br/>

            <span>
                <b>Campo de Visão:</b> 200º
            </span>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    accessory5: {
        pt_br: `Pulseiras`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    accessory5Description: {
        pt_br: <>
            <span>Pulseiras de aproximação Mifare, em silicone ou em descartáveis em PVC.</span>
            <br/> <br/>
            
            <span>Disponíveis em diversas cores e podendo ser personalizadas com a logomarca de seu empreendimento.</span>
            <br/> <br/>

            <span>
                <b>Faixa de operação:</b> 13.56 MHz (ISO 14443A)
            </span>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    accessory6: {
        pt_br: `Gravador de cartões`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    accessory6Description: {
        pt_br: <>
            <span> <b>Características:</b> </span>
            <ul>
                <li>Interface USB</li>
                <li>Função de leitura e escrita do cartão RFID</li>
                <li>Antena de acesso sem contato, com distância de leitura até 50mm</li>
                <li>Suporta cartões inteligentes em conformidade com a norma ISO 14443 A 14443 B</li>
                <li>Possui led indicativo de operação</li>
                <li>Sinalizador buzzer</li>
            </ul>
            <br/>

            <span> <b>Informações Técnicas:</b> </span>
            <ul>
                <li>Frequência de operação de 13.56 Mhz</li>
                <li>Forte de alimentação através de porta USB - 5V DC - 200 mA</li>
                <li>Dimensções: 120mm (L) x 73mm (W) x 20mm (H)</li>
                <li>Peso: 155g</li>
            </ul>
            <br/>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    accessory7: {
        pt_br: `Dobradiças`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    accessory7Description: {
        pt_br: <>
            <span>Dobradiça com mola interna para fechamento automático de portas comuns.</span>
            <br/> <br/>

            <span>
                <b>Aplicação:</b> portas de madeira ou metálicas
            </span>
            <br/>

            <span>
                <b>Material:</b> Aço Inox 304
            </span>
            <br/>

            <span>
                <b>Dimensões:</b> 3" x 4"
            </span>
            <br/>

            <span>
                <b>Espessura:</b> 0.7 mm
            </span>
            <br/> <br/>

            <span>Fixação Reversível</span>
            <br/>

            <span>Visual limpo e discreto</span>
            <br/>

            <span>Inclui parafusos de fixação</span>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    accessory8: {
        pt_br: `Cartões chaveiros`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    accessory8Description: {
        pt_br: <>
            <span>Ideal para ser utilizado no economizador de energia quando o empreendimento possuir chaves mecânicas nas portas dos apartamentos.</span>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    accessory9: {
        pt_br: `Barra antipânico`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    accessory9Description: {
        pt_br: <>
            <ul>
                <li>Barras Anti-Pânico conforme a NBR 13.768 (acessórios destinados à porta corta fogo para saída de emergência), confeccionada em metal reforçado;</li>
                <li>Para portas de uma folha (simples) ou de duas folhas (portas duplas);</li>
                <li>Utilização em salas de evento, auditórios e saídas de emergência</li>
                <li>Integração com fechadura eletrônica de controle de acesso, permitindo controle das entradas por horário e por usuário. O responsável pela sala, por exemplo, teria autorização de fechar e abrir a porta quantas vezes for necessário.</li>
                <li>Sistema de auditoria que informa quando a fechadura foi aberta, e por quem.</li>
            </ul>

            <br/>
            <span>
                <b>Componentes:</b> Barra de acionamento, tubo de travamento do cremone, trinco e componentes internos
            </span>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    accessory10: {
        pt_br: `Leitor de rádio-frequência`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    accessory10Description: {
        pt_br: <>
            <span>Leitor de Cartões de rádio frequência padrão MIFARE.</span>
            <br/> <br/>

            <span>Ideal para mecanismos de abertura com eletroimã, portões, cancelas e totens.</span>
            <br/> <br/>

            <span>Compatível com todos os acessórios de rádio frequência da Saga, suportando cartões inteligentes em conformidade com as normas ISO 14443A E ISO 14443B</span>
            <br/> <br/>

            <span>O controle pode ser instalado para o controle de acesso da entrada de estacionamentos e a catraca poderá controlar as entradas e saídas dos funcionários e clientes.</span>
            <br/> <br/>
            
            <span> <b>Características Técnicas Cancela Garagem:</b> </span>
            <ul>
                <li>Cor da Cancela: Vermelha</li>
                <li>110V e 220V</li>
                <li>Tempo para a cancela baixar: 2s (para 1 a 3m), 6s (para 4 a 6m)</li>
                <li>Contem dois transmissores para o controle remoto de até 25m de distância.</li>
            </ul>
            <br/>

            <span> <b>Características Técnicas Catraca:</b> </span>
            <ul>
                <li>Controle de Acesso através de Software</li>
                <li>Quantidade de Usuários: Ilimitado</li>
            </ul>
            <br/>

            <span> <b>Características Técnicas Leitor Mural (portas com eletroimã, portas e portões automáticos):</b> </span>
            <ul>
                <li>Voltagem de entrada: AC 100/240V</li>
                <li>Voltagem de trabalho: DC 12V</li>
                <li>Padrão de Leitura: Itens de Radiofrequência Mifare</li>
                <li>Humidade de trabalho: 10% ~ 93%</li>
                <li>Pode acionar qualquer tipo de fechadura eletromagnética</li>
            </ul>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    accessory11: {
        pt_br: `Leitor de elevador`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    accessory11Description: {
        pt_br: <>
            <span>Controle de Acesso para Elevador</span>
            <br/> <br/>

            <ul>
                <li>Leitor de cartões Mifare que trabalha conectado a uma placa de relês. Provido de sinais luminosos para informar ao usuário sobre o resultado da leitura e validade do cartão. Lê cartões de proximidade (Mifare).</li>
                <li>Quando o leitor realiza a leitura do cartão, verifica as informações como a data de validade, hora é código do cartão. Se estes parâmetros estão corretos, lê as autorizações dadas aos cartões e ativa os relês correspondentes. Se o cartão não é válido, o leitor não ativará nenhum relê e indicará led vermelho.</li>
            </ul>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    accessory12: {
        pt_br: `Mola aérea`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    accessory12Description: {
        pt_br: <>
            <ul>
                <li>Mola Aérea, sem braço de parada, com duas válvulas para regulagem e controle da velocidade de fechamento da porta. Aconselhável para portas de Madeira, Alumínio e Vidro (para vidros, é necessário o uso de suporte para fixação).</li>
                <li>Potência 2/3/4, para portas de 850 até 1100mm de largura. É necessário o deslocamento da mola no ato da instalação para regular a potência. (Conforme gabarito que vem junto com a mola).</li>
                <li>Atenção: Não é aconselhável o uso da mola aérea em portas de vidro na posição da potência 2.</li>
            </ul>

            <br/>
            <span>
                <b>Potência 2:</b> Portas até 850mm 25-45 kg
            </span>

            <br/>
            <span>
                <b>Potência 3:</b> Portas até 950mm 40-65kg
            </span>

            <br/>
            <span>
                <b>Potência 4:</b> Portas até 1100mm 60-80kg
            </span>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    electronicLocks: {
        pt_br: `Fechaduras eletrônicas`,
        en_us: `Electronic locks`,
        es_es: `Cerraduras electrónicas`
    },

    electronicLock1: {
        pt_br: `Elegante Slim`,
        en_us: `Elegante Slim`,
        es_es: `Elegante Slim`
    },

    electronicLock1Description: {
        pt_br: <>
            <span>Fechadura eletrônica de alto padrão para hotelaria</span>
            <br/>

            <ul>
                <li>Tecnologia Mifare (de proximidade)</li>
                <li>Mais segurança</li>
                <li>Design clean, adaptável a qualquer tipo de decoração</li>
                <li>Resistente ao fogo</li>
                <li>Facilidade de uso em casos de emergência</li>
            </ul>
            <br/>

            <span> <b>Especificações gerais:</b> </span>
            <ul>
                <li>Abertura com celular utilizando Bluetooth de Baixo Consumo (BLE).</li>
                <li>Sistema anti pânico.</li>
                <li>Certificada nas normas internacionais de segurança contra propagação do fogo CE e RF.</li>
                <li>Funcionamento com 4 pilhas AA, durando em média 2 anos.</li>
                <li>Em aço inoxidável AISI304.</li>
                <li>Integração com software de gestão hoteleira.</li>
                <li>Circuito eletrônico anti-humidade adaptável a qualquer tipo de clima.</li>
                <li>Sistema de memória não volátil EPROM que garante a manutenção das informações de acesso mesmo havendo troca de pilhas.</li>
                <li>Abertura de emergência através de chave mecânica.</li>
                <li>Disponível em versão para Porta de Correr ou com máquina especial para portas com perfil estreito (entre em contato para mais informações).</li>
                <li>Aviso sonoro e visual de baterias fracas.</li>
                <li>Especificações da mecânica: Máquina EURO 22 mm com backset de 65 mm. Trinco de segurança maciço.</li>
                <li>Auditoria das últimas 400 aberturas, seja por chave mecânica ou por cartão, indicando Data; Hora; Nome de quem acessou (no caso de funcionários); Quem fez o cartão de acesso e quando fez o cartão</li>
                <li>Aplicabilidade: portas de 35-80 mm.</li>
            </ul>
            <br/>

            <span>
                <a href='https://3dwarehouse.sketchup.com/model/87f79b1d-dcf9-47f9-b6ca-c7969aadc9f7/Saga-Systems-BIM-Modelo-Fechadura-Elegante-Hotel' target="_blank" rel="noreferrer">Clique aqui</a> 
                {' '}
                para ver nosso Modelo em 3D para BIM!
            </span>
            <br/> <br/>

            <span> 
                Quer melhorar a segurança em seu hotel? Leia nosso artigo 
                {' '}
                <a href='https://saga.greenmake.com.br/blog/2020/08/05/fechaduras-para-hotel/' target="_blank" rel="noreferrer">clicando aqui</a>!
            </span>
            <br/> <br/>

            <span> 
                <b>Atenção:</b> 
                {' '}
                Os acabamentos Golden, Bronze e Preto Matte são feitos por encomenda.
            </span>
            <br/>

            <span>Favor verificar a disponibilidade com nossa equipe!</span>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    electronicLock2: {
        pt_br: `Ômega`,
        en_us: `Ômega`,
        es_es: `Ômega`
    },

    electronicLock2Description: {
        pt_br: `Descrição fechadura 2`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    electronicLock3: {
        pt_br: `New Prime`,
        en_us: `New Prime`,
        es_es: `New Prime`
    },

    electronicLock3Description: {
        pt_br: <>
            <span>Fechadura modelo bipartido com tecnologia RFID e mecânica de alta segurança para controle de acesso Saga Systems.</span>
            <br/> <br/>

            <span>Segurança total no estilo alto padrão, com seu design moderno e discreto, se adapta a qualquer ambiente ou decoração assegurando ao seu usuário maior praticidade de acesso e privacidade.</span>
            <br/> <br/>

            <span> <b>Especificações:</b> </span>
            <ul>
                <li>Fechadura Eletrônica RFID Bipartida Off-line.</li>
                <li>Máquina de embutir de alta segurança em aço inoxidável.</li>
                <li>Maçaneta em aço inoxidável com acabamento de alta duração.</li>
                <li>Fixação em 3 pontos com mecanismo antifricção, feita com ferrolhos de aço inoxidável.</li>
                <li>Máquina de 20 mm de espessura equipada com trava de alta resistência.</li>
                <li>Mecanismo antipânico: as linguetas e trava são recolhidas com um único movimento pelo lado de dentro, para saída facilitada em casos de emergência.</li>
                <li>Compatível com a ADA (para PCD).</li>
                <li>Memória Flash reprogramável.</li>
                <li>Funcionamento com 4 pilhas AA com duração acima de 1 ano.</li>
                <li>Auditoria das últimas 1.000 aberturas.</li>
                <li>Cilindro mecânico de alta segurança para abertura de emergência.</li>
            </ul>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    electronicLock4: {
        pt_br: `Fechadura RFID New`,
        en_us: `Lock RFID New`,
        es_es: `pendente`
    },

    electronicLock4Description: {
        pt_br: <>
            <span>Tecnologia e segurança para hotéis, escolas e hospitais.</span>
            <br/>

            <ul>
                <li>Tecnologia Mifare (de proximidade)</li>
                <li>Mais segurança</li>
                <li>Adaptável a qualquer porta de armário</li>
                <li>Resistente ao fogo</li>
                <li>Facilidade de uso em casos de emergência</li>
            </ul>
            <br/>

            <span> <b>Especificações gerais:</b> </span>
            <ul>
                <li>Funcionamento com 4 pilhas AA, durando mais de um ano (30.000 aberturas)</li>
                <li>Em aço inoxidável AISI304</li>
                <li>Possibilidade de criação de chave-mestra (cartão)</li>
                <li>Circuito eletrônico anti-umidade adaptável a qualquer tipo de clima.</li>
                <li>Sistema de memória não volátil EPROM que garante a manutenção das informações de acesso mesmo havendo troca de pilhas.</li>
                <li>Mecanismo de abertura de emergência em caso de esgotamento das pilhas</li>
                <li>Aviso de pilhas fracas, a partir do qual a fechadura ainda funciona por pelo menos 60 operações de abertura.</li>
                <li>Dimensões: 55 (L) x 118 (A) x 20 (P) mm (externa) e 66 (L) x 133 (A) x 27.5 (P) (interna)</li>
                <li>Aviso sonoro e visual de porta mal fechada.</li>
            </ul>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    electronicLock5: {
        pt_br: `Fechadura de Senha Touchscreen`,
        en_us: `Touchscreen Password Lock`,
        es_es: `pendente`
    },

    electronicLock5Description: {
        pt_br: <>
            <span>Tecnologia e segurança para hotéis, escolas e hospitais.</span>
            <br/>

            <ul>
                <li>Teclado Numérico Touchscreen</li>
                <li>Mais segurança</li>
                <li>Adaptável a qualquer porta de armário</li>
                <li>Resistente ao fogo</li>
                <li>Facilidade de uso em casos de emergência</li>
            </ul>
            <br/>

            <span> <b>Especificações gerais:</b> </span>
            <ul>
                <li>Funcionamento com 4 pilhas AA, durando mais de um ano (30.000 aberturas)</li>
                <li>Em aço inoxidável AISI304</li>
                <li>Possibilidade de criação de senhas-mestras</li>
                <li>Circuito eletrônico anti-umidade adaptável a qualquer tipo de clima.</li>
                <li>Sistema de memória não volátil EPROM que garante a manutenção das informações de acesso mesmo havendo troca de pilhas.</li>
                <li>Mecanismo de abertura de emergência em caso de esgotamento das pilhas</li>
                <li>Aviso de pilhas fracas, a partir do qual a fechadura ainda funciona por pelo menos 60 operações de abertura.</li>
                <li>Dimensões: 55 (L) x 118 (A) x 20 (P) mm 66 (L) x 133 (A) x 27.5 (P) (interna)</li>
                <li>Aviso sonoro e visual de porta mal fechada.</li>
            </ul>
            <br/>

            <span> 
                <a href='https://3dwarehouse.sketchup.com/model/1795306c-ad66-4c67-86e7-60899e5db576/Saga-Systems-BIM-Modelo-Fechadura-Arm%C3%A1rio-Touchscreen' target="_blank" rel="noreferrer">Clique aqui</a> 
                {' '}
                para visitar o Modelo 3D BIM para incluir em seu projeto!
            </span>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    electronicLocksDescription: {
        pt_br: `pendente`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    electronicLocksHomeDescription: {
        pt_br: `Tecnologia de segurança e controle de acesso para seu empreendimento do ramo de hospitalidade`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    electronicSafe1: {
        pt_br: `Exclusive`,
        en_us: `Exclusive`,
        es_es: `Exclusive`
    },

    electronicSafe1Description: {
        pt_br: <>
            <span> <b>Segurança eletrônica:</b> </span>
            <ul>
                <li>Mecanismo eletrônico e mecânico estão instalados na tampa da porta;</li>
                <li>Cada cofre possui duas chaves mecânicas, com um código de identificação;</li>
                <li>Eletrônica desenhada para resistir a fortes descargas elétricas;</li>
                <li>Após digitar quatro vezes o código errado, o cofre permanecerá bloqueado durante um tempo estabelecido;</li>
                <li>Possui Unidade Portátil opcional para abertura de emergência, protegido com código de acesso.</li>
            </ul>
            <br/>

            <span> <b>Segurança física:</b> </span>
            <ul>
                <li>Duas travas sólidas;</li>
                <li>Porta protegida contra inserção;</li>
                <li>Proteção interna do circuito.</li>
            </ul>
            <br/>

            <span> <b>Auditoria:</b> </span>
            <ul>
                <li>Memória não volátil</li>
                <li>Relatório das 100 últimas operações, podendo ser realizada pelo administrador do sistema de duas maneiras:</li>
                <li>Direto no teclado do cofre;</li>
                <li>Utilizando a Unidade Portátil.</li>
                <li>Data e hora da operação (abriu/fechou com senha do hóspede, senha master, Unidade Portátil ou chave mecânica).</li>
            </ul>
            <br/>

            <span> <b>Opções de abertura:</b> </span>
            <ul>
                <li>Senha do hóspede (quatro à seis dígitos);</li>
                <li>Unidade Portátil;</li>
                <li>Chave mecânica (caso de emergência).</li>
            </ul>
            <br/>

            <span> <b>Dimensões e especificações físicas:</b> </span>
            <ul>
                <li>Externas: (Altura) 190 x (Largura) 435 x (Profundidade) 370  mm</li>
                <li>Internas: (Altura) 184 x (Largura) 430 x (Profundidade) 315  mm</li>
                <li>Porta: (Altura) 154 x (Largura) 355</li>
                <li>Espessura da Chapa do Corpo: 2 mm</li>
                <li>Espessura da Chapa da Porta: 5 mm</li>
                <li>Peso Líquido: 14.5 kg</li>
            </ul>
            <br/>

            <span> <b>Instalação e manutenção:</b> </span>
            <ul>
                <li>Acompanha duas buchas de aço para a fixação do cofre.</li>
                <li>A troca das pilhas é fácil;</li>
                <li>Baixo consumo, média de dois anos de utilização (já acompanha pilhas de fábrica);</li>
                <li>Indicador do status das pilhas no visor.</li>
            </ul>
            <br/>

            <span> <b>Certificados:</b> </span>
            <ul>
                <li>AE 50043021 0001 (TUV Rheinland)</li>
                <li>ISO 9001-2008 (Certificado de qualidade de gestão)</li>
            </ul>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    electronicSafe2: {
        pt_br: `Laptop Classic Bege`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    electronicSafe2Description: {
        pt_br: <>
            <span>O cofre mais conhecido e confiado do mercado hoteleiro! Nosso cofre Classic é projetado para comportar um Laptop, além de bolsas de mão, documentos e objetos de valor.</span>
            <br/> <br/>

            <span>Os cofres eletrônicos são um produto imprescindível num apartamento de hotel, e devem ser adquiridos levando-se em consideração características de qualidade e características técnicas.</span>
            <br/> <br/>

            <span>Um cofre eletrônico bem escolhido traz tranquilidade para o hoteleiro e segurança para o hóspede.</span>
            <br/> <br/>

            <span> <b>Segurança eletrônica:</b> </span>
            <ul>
                <li>Mecanismo eletrônico e mecânico estão instalados na tampa da porta;</li>
                <li>Cada cofre possui duas chaves mecânicas, com um código de identificação;</li>
                <li>Eletrônica desenhada para resistir a fortes descargas elétricas;</li>
                <li>Após digitar quatro vezes o código errado, o cofre permanecerá bloqueado durante um tempo estabelecido;</li>
                <li>Possui Unidade Portátil opcional para abertura de emergência, protegido com código de acesso.</li>
            </ul>
            <br/>

            <span> <b>Segurança física:</b> </span>
            <ul>
                <li>Duas travas sólidas;</li>
                <li>Porta protegida contra inserção;</li>
                <li>Proteção interna do circuito.</li>
            </ul>
            <br/>

            <span> <b>Auditoria:</b> </span>
            <ul>
                <li>Memória não volátil</li>
                <li>Relatório das 100 últimas operações, podendo ser realizada pelo administrador do sistema de duas maneiras:</li>
                <li>Direto no teclado do cofre;</li>
                <li>Utilizando a Unidade Portátil.</li>
                <li>Data e hora da operação (abriu/fechou com senha do hóspede, senha master, Unidade Portátil ou chave mecânica).</li>
            </ul>
            <br/>

            <span> <b>Opções de abertura:</b> </span>
            <ul>
                <li>Senha do hóspede (quatro à seis dígitos);</li>
                <li>Unidade Portátil;</li>
                <li>Chave mecânica (caso de emergência).</li>
            </ul>
            <br/>

            <span> <b>Dimensões e especificações físicas:</b> </span>
            <ul>
                <li>Externas: (Altura) 200 x (Largura) 420 x (Profundidade) 370 mm</li>
                <li>Peso: 12 kg</li>
                <li>Internas: (Altura) 195 x (Largura) 417 x (Profundidade) 318 mm</li>
            </ul>
            <br/>

            <span> <b>Instalação e manutenção:</b> </span>
            <ul>
                <li>Acompanha duas buchas de aço para a fixação do cofre.</li>
                <li>A troca das pilhas é fácil;</li>
                <li>Baixo consumo, média de dois anos de utilização (já acompanha pilhas de fábrica);</li>
                <li>Indicador do status das pilhas no visor.</li>
            </ul>
            <br/>

            <span>Disponível nas cores Preto e Bege</span>
            <br/>

            <span>Para mais opções de cores, entrar em contato conosco</span>
            <br/> <br/>

            <span> 
                Veja a publicação em nosso blog sobre como escolher um bom cofre para seu hotel
                {' '}
                <a href='https://saga.greenmake.com.br/blog/2019/05/08/cofre-para-hotel/' target="_blank" rel="noreferrer">clicando aqui</a>! 
            </span>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    electronicSafe3: {
        pt_br: `Laptop Classic Preto`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    electronicSafe3Description: {
        pt_br: <>
            <span>O cofre mais conhecido e confiado do mercado hoteleiro! Projetado para comportar um Laptop, além de bolsas de mão e objetos de valor.</span>
            <br/> <br/>

            <span> <b>Segurança eletrônica:</b> </span>
            <ul>
                <li>Mecanismo eletrônico e mecânico estão instalados na tampa da porta;</li>
                <li>Cada cofre possui duas chaves mecânicas, com um código de identificação;</li>
                <li>Eletrônica desenhada para resistir a fortes descargas elétricas;</li>
                <li>Após digitar quatro vezes o código errado, o cofre permanecerá bloqueado durante um tempo estabelecido;</li>
                <li>Possui Unidade Portátil opcional para abertura de emergência, protegido com código de acesso.</li>
            </ul>
            <br/>

            <span> <b>Segurança física:</b> </span>
            <ul>
                <li>Duas travas sólidas;</li>
                <li>Porta protegida contra inserção;</li>
                <li>Proteção interna do circuito.</li>
            </ul>
            <br/>

            <span> <b>Auditoria:</b> </span>
            <ul>
                <li>Memória não volátil</li>
                <li>Relatório das 100 últimas operações, podendo ser realizada pelo administrador do sistema de duas maneiras:</li>
                <li>Direto no teclado do cofre;</li>
                <li>Utilizando a Unidade Portátil.</li>
                <li>Data e hora da operação (abriu/fechou com senha do hóspede, senha master, Unidade Portátil ou chave mecânica).</li>
            </ul>
            <br/>

            <span> <b>Opções de abertura:</b> </span>
            <ul>
                <li>Senha do hóspede (quatro à seis dígitos);</li>
                <li>Unidade Portátil;</li>
                <li>Chave mecânica (caso de emergência).</li>
            </ul>
            <br/>

            <span> <b>Dimensões e especificações físicas:</b> </span>
            <ul>
                <li>Externas: (Altura) 200 x (Largura) 420 x (Profundidade) 370 mm</li>
                <li>Peso: 12 kg</li>
                <li>Internas: (Altura) 195 x (Largura) 417 x (Profundidade) 318 mm</li>
            </ul>
            <br/>

            <span> <b>Instalação e manutenção:</b> </span>
            <ul>
                <li>Acompanha duas buchas de aço para a fixação do cofre.</li>
                <li>A troca das pilhas é fácil;</li>
                <li>Baixo consumo, média de dois anos de utilização (já acompanha pilhas de fábrica);</li>
                <li>Indicador do status das pilhas no visor.</li>
            </ul>
            <br/>

            <span>Disponível nas cores Preto e Bege</span>
            <br/>

            <span>Para mais opções de cores, entrar em contato conosco</span>
            <br/> <br/>

            <span> 
                <a href='https://3dwarehouse.sketchup.com/model/264ffda6-a123-46bc-98cc-ea3f7143403b/Saga-Systems-BIM-Modelo-Cofre-Classic-Laptop-Preto' target="_blank" rel="noreferrer">Clique aqui</a>
                {' '}
                para acessar e baixar o modelo 3D para BIM do Cofre Laptop Classic na cor Preto!
            </span>
            <br/> <br/>

            <span> 
                Veja a publicação em nosso blog sobre como escolher um bom cofre para seu hotel
                {' '}
                <a href='https://saga.greenmake.com.br/blog/2018/11/21/1970/' target="_blank" rel="noreferrer">clicando aqui</a>! 
            </span>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    electronicSafe4: {
        pt_br: `Unidade Portátil (CEU)`,
        en_us: `Unidade Portátil (CEU)`,
        es_es: `Unidade Portátil (CEU)`
    },

    electronicSafe4Description: {
        pt_br: <>
            <span>Conecta-se externamente ao cofre para operações de leitura dos registros de auditoria, programação do número do apartamento e abertura de emergência.</span>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    electronicSafes: {
        pt_br: `Cofres eletrônicos`,
        en_us: `Electronic safes`,
        es_es: `Cajas fuertes electrónicas`
    },

    electronicSafesDescription: {
        pt_br: `pendente`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    electronicSafesHomeDescription: {
        pt_br: `Um item indispensável para a segurança e tranquilidade de seus hóspedes`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaver1: {
        pt_br: `Eco Line`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaver1Description: {
        pt_br: `Descrição economizador de energia 1`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaver2: {
        pt_br: `RFID 01 Interruptores de Luz`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaver2Description: {
        pt_br: <>
            <span>Sustentabilidade em economizadores de energia para hotéis.</span>
            <br/> <br/>

            <span> <b>Funcionamento:</b> </span>
            <br/>

            <span>O economizador de energia é um dispositivo que libera a energia elétrica de um quarto de hotel no momento em que é inserido em sua fenda um cartão, o mesmo utilizado para abrir a porta (com fechadura eletrônica).
            <br/>

            <span>Comuta através de contatores ou diretamente os circuitos de tomadas, iluminação do quarto, aparelho de ar condicionado, etc.</span>
            <br/>

            <span>Quando o cartão é retirado do economizador os circuitos são desligados após aproximadamente 60 segundos, dando tempo para o hóspede sair do quarto. Neste momento duas lâmpadas piloto, no corpo do economizador, acendem para orientar e facilitar uma futura inserção do cartão.</span>
            <br/>

            <span>Possui 1 interruptor para lâmpada e somente funciona mediante a inserção de cartão com chip RFID, mifare (proximidade), devido à existência do sensor de rádio-frequência.</span></span>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaver3: {
        pt_br: `RFID 02 Interruptores de Luz`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaver3Description: {
        pt_br: <>
            <span>Sustentabilidade em economizadores de energia para hotéis.</span>
            <br/> <br/>

            <span> <b>Funcionamento:</b> </span>
            <br/>

            <span>O economizador de energia é um dispositivo que libera a energia elétrica de um quarto de hotel no momento em que é inserido em sua fenda um cartão, o mesmo utilizado para abrir a porta (com fechadura eletrônica).</span>
            <br/>

            <span>Comuta através de contatores ou diretamente os circuitos de tomadas, iluminação do quarto, aparelho de ar condicionado, etc.</span>
            <br/>

            <span>Quando o cartão é retirado do economizador os circuitos são desligados após aproximadamente 60 segundos, dando tempo para o hóspede sair do quarto. Neste momento duas lâmpadas piloto, no corpo do economizador, acendem para orientar e facilitar uma futura inserção do cartão.</span>
            <br/>

            <span>Possui 2 interruptores para lâmpadas e somente funciona mediante a inserção de cartão com chip RFID, mifare (proximidade), devido à existência do sensor de rádio-frequência.</span>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaver4: {
        pt_br: `RFID`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaver4Description: {
        pt_br: <>
            <span>Sustentabilidade em economizadores de energia para hotéis.</span>
            <br/> <br/>

            <span> <b>Funcionamento:</b> </span>
            <br/>

            <span>O economizador de energia é um dispositivo que libera a energia elétrica de um quarto de hotel no momento em que é inserido em sua fenda um cartão, o mesmo utilizado para abrir a porta (com fechadura eletrônica).</span>
            <br/>

            <span>Comuta através de contatores ou diretamente os circuitos de tomadas, iluminação do quarto, aparelho de ar condicionado, etc.</span>
            <br/>

            <span>Quando o cartão é retirado do economizador os circuitos são desligados após aproximadamente 60 segundos, dando tempo para o hóspede sair do quarto. Neste momento duas lâmpadas piloto, no corpo do economizador, acendem para orientar e facilitar uma futura inserção do cartão.</span>
            <br/>

            <span>Somente funciona mediante a inserção de cartão com chip RFID, mifare (proximidade), devido à existência do sensor de rádio-frequência.</span>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaver5: {
        pt_br: `RFID 01 Interruptor de Luz e Não Perturbe`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaver5Description: {
        pt_br: <>
            <span>Sustentabilidade em economizadores de energia para hotéis.</span>
            <br/> <br/>

            <span> <b>Funcionamento:</b> </span>
            <br/>

            <span>O economizador de energia é um dispositivo que libera a energia elétrica de um quarto de hotel no momento em que é inserido em sua fenda um cartão, o mesmo utilizado para abrir a porta (com fechadura eletrônica).</span>
            <br/>
            
            <span>Comuta através de contatores ou diretamente os circuitos de tomadas, iluminação do quarto, aparelho de ar condicionado, etc.</span>
            <br/>
            
            <span>Quando o cartão é retirado do economizador os circuitos são desligados após aproximadamente 60 segundos, dando tempo para o hóspede sair do quarto. Neste momento duas lâmpadas piloto, no corpo do economizador, acendem para orientar e facilitar uma futura inserção do cartão.</span>
            <br/>
            
            <span>Possui 1 interruptor para lâmpada e sistema de Não Perturbe. Somente funciona mediante a inserção de cartão com chip RFID, mifare (proximidade), devido à existência do sensor.</span>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaver6: {
        pt_br: `RFID Não Perturbe`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaver6Description: {
        pt_br: <>
            <span>Sustentabilidade em economizadores de energia para hotéis.</span>
            <br/> <br/>

            <span> <b>Funcionamento:</b> </span>
            <br/>

            <span>O economizador de energia é um dispositivo que libera a energia elétrica de um quarto de hotel no momento em que é inserido em sua fenda um cartão, o mesmo utilizado para abrir a porta (com fechadura eletrônica).</span>
            <br/>

            <span>Comuta através de contatores ou diretamente os circuitos de tomadas, iluminação do quarto, aparelho de ar condicionado, etc.</span>
            <br/>

            <span>Quando o cartão é retirado do economizador os circuitos são desligados após aproximadamente 60 segundos, dando tempo para o hóspede sair do quarto. Neste momento duas lâmpadas piloto, no corpo do economizador, acendem para orientar e facilitar uma futura inserção do cartão.</span>
            <br/>

            <span>Possui sistema de Não Perturbe e somente funciona mediante a inserção de cartão com chip RFID, mifare (proximidade), devido à existência do sensor de rádio-frequência.</span>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaver7: {
        pt_br: `Ótico 01 Interruptor de Luz, Não Perturbe e Campainha`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaver7Description: {
        pt_br: <>
            <span>Sustentabilidade em economizadores de energia para hotéis.</span>
            <br/> <br/>

            <span> <b>Funcionamento:</b> </span>
            <br/>

            <span>O economizador de energia é um dispositivo que libera a energia elétrica de um quarto de hotel no momento em que é inserido em sua fenda um cartão, o mesmo utilizado para abrir a porta (com fechadura eletrônica).</span>
            <br/>

            <span>Comuta através de contatores ou diretamente os circuitos de tomadas, iluminação do quarto, aparelho de ar condicionado, etc.</span>
            <br/>

            <span>Quando o cartão é retirado do economizador os circuitos são desligados após aproximadamente 60 segundos, dando tempo para o hóspede sair do quarto. Neste momento duas lâmpadas piloto, no corpo do economizador, acendem para orientar e facilitar uma futura inserção do cartão.</span>
            <br/>

            <span>Possui 1 interruptor para lâmpada, sistema de Não Perturbe, botão acionador de campainha e somente funciona mediante inserção de Cartão Magnético ou Cartão Chaveiro, devido à existência do sensor ótico.</span>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaver8: {
        pt_br: `Ótico 02 Interruptor de Luz, Não Perturbe e Campainha`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaver8Description: {
        pt_br: <>
            <span>Sustentabilidade em economizadores de energia para hotéis.</span>
            <br/> <br/>

            <span> <b>Funcionamento:</b> </span>
            <br/>

            <span>O economizador de energia é um dispositivo que libera a energia elétrica de um quarto de hotel no momento em que é inserido em sua fenda um cartão, o mesmo utilizado para abrir a porta (com fechadura eletrônica).</span>
            <br/>
            
            <span>Comuta através de contatores ou diretamente os circuitos de tomadas, iluminação do quarto, aparelho de ar condicionado, etc.</span>
            <br/>
            
            <span>Quando o cartão é retirado do economizador os circuitos são desligados após aproximadamente 60 segundos, dando tempo para o hóspede sair do quarto. Neste momento duas lâmpadas piloto, no corpo do economizador, acendem para orientar e facilitar uma futura inserção do cartão.</span>
            <br/>
            
            <span>Possui 2 interruptores para lâmpada, sistema de Não Perturbe, botão acionador de campainha e somente funciona mediante a inserção de Cartão Magnético ou Cartão Chaveiro, devido à existência do sensor ótico.</span>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaver9: {
        pt_br: `Chave Contatora`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaver9Description: {
        pt_br: <>
            <span>Sustentabilidade em economizadores de energia para hotéis.</span>
            <br/> <br/>

            <span> <b>Funcionamento:</b> </span>
            <br/>

            <span> <b>Tensão de alimentação:</b> 110 ou 220 Volts (não bivolt automática).</span>
            <br/>

            <span> <b>Cargas não indutivas:</b> 20A.</span>
            <br/>

            <span> <b>Tensão de isolação:</b> 750 Volts (somente para uso industrial, máximo de tensão suportada).</span>
            <br/>

            <span> <b>Número de Pólos:</b> 04 pólos.</span>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaver10: {
        pt_br: `Ótico 01 Interruptor de Luz e Não Perturbe`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaver10Description: {
        pt_br: <>
            <span>Sustentabilidade em economizadores de energia para hotéis.</span>
            <br/> <br/>

            <span> <b>Funcionamento:</b> </span>
            <br/>

            <span>O economizador de energia é um dispositivo que libera a energia elétrica de um quarto de hotel no momento em que é inserido em sua fenda um cartão, o mesmo utilizado para abrir a porta (com fechadura eletrônica).</span>
            <br/>

            <span>Comuta através de contatores ou diretamente os circuitos de tomadas, iluminação do quarto, aparelho de ar condicionado, etc.</span>
            <br/>

            <span>Quando o cartão é retirado do economizador os circuitos são desligados após aproximadamente 60 segundos, dando tempo para o hóspede sair do quarto. Neste momento duas lâmpadas piloto, no corpo do economizador, acendem para orientar e facilitar uma futura inserção do cartão.</span>
            <br/>

            <span>Possui 1 interruptor para lâmpada, sistema de Não Perturbe, botão acionador de campainha e somente funciona mediante inserção de Cartão Magnético ou Cartão Chaveiro, devido à existência do sensor ótico.</span>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaver11: {
        pt_br: `Ótico Não Perturbe e Campainha`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaver11Description: {
        pt_br: <>
            <span>Sustentabilidade em economizadores de energia para hotéis.</span>
            <br/> <br/>

            <span> <b>Funcionamento:</b> </span>
            <br/>

            <span>O economizador de energia é um dispositivo que libera a energia elétrica de um quarto de hotel no momento em que é inserido em sua fenda um cartão, o mesmo utilizado para abrir a porta (com fechadura eletrônica).</span>
            <br/>

            <span>Comuta através de contatores ou diretamente os circuitos de tomadas, iluminação do quarto, aparelho de ar condicionado, etc.</span>
            <br/>

            <span>Quando o cartão é retirado do economizador os circuitos são desligados após aproximadamente 60 segundos, dando tempo para o hóspede sair do quarto. Neste momento duas lâmpadas piloto, no corpo do economizador, acendem para orientar e facilitar uma futura inserção do cartão.</span>
            <br/>

            <span>Possui sistema de Não Perturbe, botão acionador de campainha e somente funciona mediante a inserção de cartão com banda magnética ou cartão chaveiro, devido à existência do sensor ótico.</span>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaver12: {
        pt_br: `Ótico 02 Interruptores de Luz`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaver12Description: {
        pt_br: <>
            <span>Sustentabilidade em economizadores de energia para hotéis.</span>
            <br/> <br/>

            <span> <b>Funcionamento:</b> </span>
            <br/>

            <span>O economizador de energia é um dispositivo que libera a energia elétrica de um quarto de hotel no momento em que é inserido em sua fenda um cartão, o mesmo utilizado para abrir a porta (com fechadura eletrônica).</span>
            <br/>

            <span>Comuta através de contatores ou diretamente os circuitos de tomadas, iluminação do quarto, aparelho de ar condicionado, etc.</span>
            <br/>

            <span>Quando o cartão é retirado do economizador os circuitos são desligados após aproximadamente 60 segundos, dando tempo para o hóspede sair do quarto. Neste momento duas lâmpadas piloto, no corpo do economizador, acendem para orientar e facilitar uma futura inserção do cartão.</span>
            <br/>

            <span>Possui 2 interruptores para lâmpadas e somente funciona mediante a inserção de cartão com banda magnética ou cartão chaveiro, devido à existência do sensor ótico.</span>
        </>,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySavers: {
        pt_br: `Economizadores de energia`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaversDescription: {
        pt_br: `pendente`,
        en_us: `pendente`,
        es_es: `pendente`
    },

    energySaversHomeDescription: {
        pt_br: `Sustentabilidade, economia de energia e prevenção de acidentes elétricos.`,
        en_us: `pendente`,
        es_es: `pendente`
    },
}

export default ProductsTranslation;